// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material
@include mat.all-component-typographies();
@include mat.core();

// Qreddi Purple/Blue palette (primary)
$qr-purple-blue-palette: (
  100: #6f62c5,
  500: #3d3894,
  700: #001265,
  contrast: (
    100: white,
    500: white,
    700: white,
  ),
);

// Qreddi Light Blue palette (secondary)
$qr-light-blue-palette: (
  100: #9ff6fb,
  500: #6cc3c8,
  700: #379297,
  contrast: (
    100: #333,
    500: #333,
    700: #000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tvh-wassalons-ui-primary: mat.define-palette($qr-purple-blue-palette);
$tvh-wassalons-ui-accent: mat.define-palette($qr-light-blue-palette);

// The warn palette is optional (defaults to red).
$tvh-wassalons-ui-warn: mat.define-palette(mat.$red-palette);

// The success palette
$tvh-wassalons-ui-success: mat.define-palette(mat.$green-palette);

// The inactive palette
$tvh-wassalons-ui-inactive: mat.define-palette(mat.$grey-palette);

// Add custom palette colors
@function tvh-light-theme(
  $primary,
  $accent,
  $warn: $tvh-wassalons-ui-warn,
  $success: $tvh-wassalons-ui-success,
  $inactive: $tvh-wassalons-ui-inactive
) {
  @return map-merge(
    mat.define-light-theme(
      (
        color: (
          primary: $primary,
          accent: $accent,
          warn: $warn,
        ),
        typography: mat.define-typography-config(),
      )
    ),
    (
      success: $success,
      inactive: $inactive,
    )
  );
}

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tvh-wassalons-ui-theme: tvh-light-theme($tvh-wassalons-ui-primary, $tvh-wassalons-ui-accent, $tvh-wassalons-ui-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($tvh-wassalons-ui-theme);

$success: map-get($tvh-wassalons-ui-theme, success);
$success-color: mat.get-color-from-palette($success);
$success-contrast: mat.get-color-from-palette($success, default-contrast);

$inactive: map-get($tvh-wassalons-ui-theme, inactive);
$inactive-color: mat.get-color-from-palette($inactive);
$inactive-contrast: mat.get-color-from-palette($inactive, default-contrast);

.mat-success {
  color: $success-color;
}

.mat-inactive {
  color: $inactive-color;
}

$primary-color: mat.get-color-from-palette($tvh-wassalons-ui-primary);
.mat-color-primary {
  color: $primary-color;
}
//
//$accent-color: mat-color($tvh-wassalons-ui-accent);
//.mat-accent {
//  color: $accent-color;
//}
//
$warn-color: mat.get-color-from-palette($tvh-wassalons-ui-warn);
.mat-warn {
  color: $warn-color;
}

/* You can add global styles to this file, and also import other style files */
@import 'vars';

html {
  height: 100%;
}
body {
  height: calc(100% - #{$header-height});
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/* Global content & button styles */
.admin-content,
.content {
  padding: 0 2em;
  margin: 0 auto;
  max-width: 600px;
}

.admin-content {
  display: block;

  mat-form-field {
    width: 100%;
  }
  mat-paginator {
    mat-form-field {
      width: 65px;
    }
  }
}

.content {
  display: flex;
  padding: 0 2em;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > button {
    margin: 1em;
  }

  button {
    width: 100%;
    padding: 1.6rem;
  }
}

/* Machine Card shared styles */
$lighten_pct: 25%;
.machine-card {
  &.disabled {
    mat-icon {
      color: lighten(mat.get-color-from-palette($tvh-wassalons-ui-warn), $lighten_pct);
    }
    .mat-badge-content {
      background-color: lighten(mat.get-color-from-palette($tvh-wassalons-ui-accent), $lighten_pct);
      color: lighten(mat.get-color-from-palette($tvh-wassalons-ui-accent, default-contrast), $lighten_pct);
    }
  }
}

.machine-icon {
  &.available {
    color: $success-color;
  }
}
